import axios from 'axios'
import store from '@/store'
import {socket} from '@/utils/socket'
import {downloadBlob} from "../../utils/download";
import {v4 as uuid} from "uuid";

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL.replace(/\/*$/, '/') + 'logic',
  withCredentials: true,
})
api.interceptors.request.use(config => {
  store.commit('Loading', true)
  return {
    ...config,
    headers: {
      ...config.headers,
      socket: socket.id,
    },
  }
})
api.interceptors.response.use(
  response => {
    store.commit('Loading', false)
    const xerror = response.headers['x-error']
    if (xerror)
      store.commit('toast', [
        decodeURIComponent(response.headers['operation-name']) +
        '\n' +
        decodeURIComponent(xerror),
        'warn',
      ])
    return response.data
  },
  error => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      if (store.state.user.isLoggedIn) store.dispatch('Logout')
    } else {
      let msg =
        error.response.data?.message ||
        error.response.data?.Status ||
        error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios (rules): ${error.message}. ${error.response.data}`)
  },
)

export const newId = () => `temp_${uuid().replace(/-/g, '')}`

const state = {
  rules: [],
  active: null,
}

const mutations = {
  SetRules(state, payload) {
    for (let rule of payload) {
      rule.active = rule.active === '1'
      rule.duplicate = rule.duplicate === '1'
      rule.constraint = rule.constraint === '1'
      rule.must_not_match = rule.must_not_match === '1'
      rule.mandatory = rule.mandatory === '1'
      
      rule.parentObj = this.state.rulesGroups.groups.flat.find(
        el => el.id === rule.section,
      )
      
      rule.apply_to = (rule.apply_to ?? '').split(';')
      rule.for_systems = rule.for_systems ? rule.for_systems.split(';') : []
      rule.exclude_systems = rule.exclude_systems
        ? rule.exclude_systems.split(';')
        : []
      
      delete rule.section
    }
    state.rules = payload
  },
  ToggleActiveRule(state, payload) {
    state.active = payload
    this.commit('SetPage', 2)
  },
  Add(state) {
    state.active = {
      name: null,
      parentObj: null,
      active: false,
      priority: null,
      var_count: '3',
      apply_to: [],
      duplicate: false,
      constraint: false,
      must_not_match: false,
      recursive_vars: null,
      mandatory: false,
      duplicate_type: '0',
      for_systems: [],
      exclude_systems: [],
      comment: null,
    }
    this.commit('SetPage', 3)
  }
}

const actions = {
  async LoadRules(context, payload) {
    context.commit('SetRules', await api.get(`groups/${payload}/rules`))
  },
  async CreateRule(context, rule) {
    rule = structuredClone(rule)
    const groupId = rule.parentObj.id
    rule.section = rule.parentObj.id
    delete rule.parentObj
    //Замена булевых значений на числовые
    for (const [key, value] of Object.entries(rule))
      if (typeof value == 'boolean') rule[key] = +value
    
    const ruleId = await api.post(`groups/${groupId}/rules`, rule)
    
    await context.dispatch('LoadRules', context.rootState.rulesGroups.active.id)
    let find = context.state.rules.find(el => el.id === `${ruleId}`)
    
    if (find) {
      context.commit('ToggleActiveRule', find)
    } else {
      context.commit('SetPage', 0)
    }
  },
  async SaveRule(context, rule) {
    rule = structuredClone(rule)
    const groupId = rule.parentObj.id
    rule.section = rule.parentObj.id
    delete rule.parentObj
    delete rule.select
    //Замена булевых значений на числовые
    for (const [key, value] of Object.entries(rule))
      if (typeof value == 'boolean') rule[key] = +value
    
    await api.patch(`groups/${groupId}/rules`, rule)
    
    await context.dispatch('LoadRules', context.rootState.rulesGroups.active.id)
  },
  async DeleteRules(context, ids) {
    const groupId = context.rootState.rulesGroups.active.id
    await api.post(`groups/${groupId}/rules_delete`, ids)
    await context.dispatch('LoadRules', groupId)
  },
  async MoveRules(context, payload) {
    await api.patch('move', {rules: payload.ids, target: payload.groupId})
    const groupId = context.rootState.rulesGroups.active.id
    await context.dispatch('LoadRules', groupId)
  },
  async export(context, payload) {
    const groupId = context.rootState.rulesGroups.active.id
    const ClearId = (group) => {
      delete group.id
      for (let _group of (group.groups ?? [])) {
        ClearId(_group)
      }
      for (let condition of (group.conditions ?? [])) {
        delete condition.id
      }
    }
    const _rules = await api.get(`groups/${groupId}/rules`)
    const rules = await Promise.all(
      payload.map(id => new Promise(
        async (resolve) => {
          
          const rule = structuredClone(_rules.find(el => el.id === id))
          delete rule.id
          delete rule.checked
          delete rule.parentObj
          let data = await api.get(`groups/${groupId}/rules/${id}`)
          for (let gr of data){
            ClearId(gr)
          }
          rule.conditions = data
          
          resolve(rule)
        })
      )
    )
  
    downloadBlob(
      JSON.stringify(rules, null, 2),
      'export.json',
      'application/json',
    )
  },
  async import(context, data) {
    const AddTempId = (group) => {
      group.id = newId()
      for (let _group of (group.groups ?? [])) {
        AddTempId(_group)
      }
      for (let condition of (group.conditions ?? [])) {
        condition.id = newId()
      }
    }
    const groupId = context.rootState.rulesGroups.active.id
    for (let item of data) {
      try {
        const rule = structuredClone(item)
        const conditions = rule.conditions
        
        rule.section = groupId
        
        delete rule.conditions
        
        for (let cond of conditions){
          AddTempId(cond)
        }
        
        const ruleId = await api.post(`groups/${groupId}/rules`, rule)
        
        await api.post(`groups/${groupId}/rules/${ruleId}`, conditions)
      } catch (e) {
        console.warn(e)
      }
    }
    context.dispatch('LoadRules', groupId)
  },
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
}
